<template>
<v-card dark class="backBlueDarken backBlueLight--text" :loading="loading" outlined elevation="3">

	<v-app-bar dark class="blue-grey darken-2">
		<v-toolbar-title>INVOICE</v-toolbar-title>
	</v-app-bar>

	<v-card-text class="blue-grey">
		<v-form ref="form">
			<v-row>
				<v-col>
					<v-switch
						inset
						:label="`Due: ${dueOn ? 'YES' : 'NO'}`"
						color="success"
						v-model="dueOn"
					/>
					<v-text-field color="accent" v-model="dueUrl" label="URL Invoice" dense filled />
					<v-alert dense type="success" v-if="successful"> Update successful</v-alert>
				</v-col>
			</v-row>
		</v-form>
	</v-card-text>
	<v-card-actions class="blue-grey darken-2">
		<v-spacer />
		<v-btn :loading=saving color="primary" @click="updateInvoice">
			Update
		</v-btn>
	</v-card-actions>

</v-card>
</template>

<script>
	import { dbFb } from '@/main';
	export default {
		name: 'showDue',
		data: () => ({
			loading: false,
			successful: false,
			saving: false,
			dueOn: false,
			dueUrl: ''
		}),
	mounted() {
		this.getInfo()
	},
		methods: {
			getInfo() {
				let me = this
				let _imei = this.$route.params.imei
				dbFb.ref(`/${_imei}/info`).once('value')
					.then(function (snap) {
						const info = snap.val();
						me.dueOn = info.dueon ? info.dueon : false
						me.dueUrl = info.dueurl ? info.dueurl : ''
					})

			},
			updateInvoice() {
				let _imei = this.$route.params.imei
				let me = this
				me.saving = true;
				dbFb.ref(`/${_imei}/info`).update({
						dueon: me.dueOn,
						dueurl: me.dueUrl,
					}).then(docRef => {
						me.successful = true
						me.saving = false
						setTimeout(() => me.successful = false, 3000);
					})
					.catch(function (error) {
						me.saving = false
						console.log("Error :", error);
					});
			},
		}

	}
</script>

<style lang="scss" scoped>

</style>